<template>
  <view-item-content :disabled="!serviceData.id">
    <en-card v-loading="loading" body-class="flex gap-6 text-sm">
      <span>{{ serviceData.vehicle?.plateNo }}</span>
      <span>{{ serviceData.sender }}/{{ serviceData.senderTelephone }}</span>
      <span>单号：{{ serviceData.serialNo }}</span>
      <span>{{ serviceData.vehicle?.vehicleSpec.join('/') }}</span>
      <span>{{ serviceData.vehicle?.vin }}</span>
    </en-card>

    <en-card v-loading="loading" class="flex-1 overflow-auto" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <template v-if="showVisit" #operation>
              <button-ajax :method="operation.submit.click" :disabled="form.loading"> 确定 </button-ajax>
            </template>

            <en-tab-pane v-if="showVisit" label="回访" name="visit">
              <en-scrollbar :height="height - 55">
                <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-3 gap-x-6">
                  <en-form-item label="回访对象" prop="intervieweeType.code">
                    <select-maintain
                      v-model="form.data.intervieweeType"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['ITVETYPE']) }"
                      :props="{ label: 'message', value: '' }"
                      value-key="code"
                      :disabled="form.loading"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="回访状态" prop="status.code">
                    <select-maintain
                      v-model="form.data.status"
                      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
                      :props="{ label: (option: EnocloudCommonDefinitions['LookupDto']) => (option.code === 'Y' ? '成功' : '失败'), value: '' }"
                      value-key="code"
                      :disabled="form.loading"
                      class="w-full"
                    ></select-maintain>
                  </en-form-item>
                  <en-form-item label="再次跟进">
                    <en-date-picker
                      :model-value="form.data.serviceReturnVisit?.datetime"
                      type="datetime"
                      value-format="YYYY-MM-DDThh:mm:ss"
                      :disabled="form.loading"
                      class="w-full"
                      @change="form.datetime.change"
                    ></en-date-picker>
                  </en-form-item>
                  <en-form-item label="回访备注">
                    <en-input v-model="form.data.comment" type="textarea" :disabled="form.loading"></en-input>
                  </en-form-item>
                  <en-form-item label="总体评分">
                    <en-rate v-model="form.data.level" :disabled="form.loading"></en-rate>
                  </en-form-item>
                  <en-form-item label="回访问卷" class="col-span-3">
                    <div class="flex flex-col">
                      <div v-for="(item, index) of form.data.messages" class="flex flex-col">
                        <span>问卷 {{ index + 1 }}：{{ item.message }}</span>
                        <en-rate
                          :model-value="Number(item.level)"
                          :disabled="form.loading"
                          @change="form.messages.level.change(item, $event)"
                        ></en-rate>
                      </div>
                    </div>
                  </en-form-item>
                </en-form>
              </en-scrollbar>
            </en-tab-pane>

            <en-tab-pane label="维修结算清单" name="settlement"> </en-tab-pane>

            <en-tab-pane label="附表结算单" name="fake"> </en-tab-pane>

            <en-tab-pane label="历史维修项目" name="maintenance-history">
              <table-dynamic
                code="SRDMTHF"
                :height="height - 55"
                :data="maintenance.data"
                :method="maintenance.get"
                :paging="maintenance.paging"
                :loading="maintenance.loading"
                pagination
              ></table-dynamic>
            </en-tab-pane>

            <en-tab-pane label="历史维修配件" name="material-history">
              <table-dynamic
                code="SRDMRHF"
                :height="height - 55"
                :data="material.data"
                :method="material.get"
                :paging="material.paging"
                :loading="material.loading"
                pagination
              ></table-dynamic>
            </en-tab-pane>

            <en-tab-pane label="评价信息" name="insurance">
              <en-table :data="serviceData?.serviceEstimates" :loading="loading" :height="height - 55">
                <en-table-column label="整体评价">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    <en-rate :model-value="row.advisorLevel" disabled></en-rate>
                  </template>
                </en-table-column>
                <en-table-column label="评价时间" prop="preparedDatetime">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceEstimateDto'] }">
                    {{ formatDate(row.preparedDatetime, true) }}
                  </template>
                </en-table-column>
                <en-table-column label="评价内容" prop="comment"></en-table-column>
              </en-table>
            </en-tab-pane>

            <en-tab-pane v-if="store.accessRightsHash.SERVICE_RETURN_VISIT_HISTORY_QUERY" label="回访记录" name="record">
              <en-table :data="record.data" :loading="record.loading" :height="height - 55" pagination :paging="record.paging">
                <en-table-column label="回访时间" prop="preparedDatetime">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                    {{ formatDate(row.preparedDatetime, true) }}
                  </template>
                </en-table-column>
                <en-table-column label="回访对象" prop="intervieweeType.message"></en-table-column>
                <en-table-column label="回访专员" prop="preparedBy.name"></en-table-column>
                <en-table-column label="回访方式" prop="followUpType.message"></en-table-column>
                <en-table-column label="回访状态">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                    {{ row.status?.value ? '成功' : '失败' }}
                  </template>
                </en-table-column>
                <en-table-column label="总体评分">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                    <en-rate :model-value="row.level" disabled></en-rate>
                  </template>
                </en-table-column>
                <en-table-column label="回访备注" prop="comment"></en-table-column>
              </en-table>
            </en-tab-pane>

            <en-tab-pane label="投诉处理" name="complaint">
              <en-table :data="complaint.data" :loading="complaint.loading" :height="height - 55">
                <en-table-column label="投诉编号" prop="serialNo" width="100"></en-table-column>
                <en-table-column label="投诉状态" prop="status.message" width="100"></en-table-column>
                <en-table-column label="工单号" prop="service.serialNo"></en-table-column>
                <en-table-column label="服务顾问" prop="service.advisor.name" width="100"></en-table-column>
                <en-table-column label="回访人员" prop="visitor.name" width="100"></en-table-column>
                <en-table-column label="车牌号" prop="service.vehicle.plateNo" width="100"></en-table-column>
                <en-table-column label="车主" prop="service.vehicle.owner.name" width="100"></en-table-column>
                <en-table-column label="联系电话" prop="service.vehicle.owner.cellphone" width="100"></en-table-column>
                <en-table-column label="进厂日期" prop="service.enterDatetime" width="100"></en-table-column>
                <en-table-column label="投诉日期" prop="complaintDate" width="100">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                    {{ formatDate(row.complaintDate) }}
                  </template>
                </en-table-column>
                <en-table-column label="投诉来源" prop="way" width="100"> </en-table-column>
                <en-table-column label="投诉对象" width="100">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                    {{ (row.targets as Array<{ message: string }>).map((item) => item.message).join(',') }}
                  </template>
                </en-table-column>
                <en-table-column label="有效投诉" prop="valid.message" width="100"></en-table-column>
                <en-table-column label="责任人" prop="liablePersons">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                    {{ row.liablePersons.map((item) => item.name).join(',') }}
                  </template>
                </en-table-column>
                <en-table-column label="服务经理" prop="serviceManager.name" width="100"></en-table-column>
                <en-table-column label="预约进厂检查日期" prop="timeToInspect" width="140">
                  <template #default="{ row }: { row: EnocloudServiceDefinitions['ComplaintDto'] }">
                    {{ formatDate(row.timeToInspect) }}
                  </template>
                </en-table-column>
                <en-table-column label="客户再次回访结果" prop="revisitCSI.message" width="140"></en-table-column>
              </en-table>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item-content>
</template>

<script lang="ts">
const formDataInit = (): EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] => {
  return {
    intervieweeType: { message: '', type: '', code: '', description: '' },
    status: { message: '', type: '', code: '', description: '' },
    comment: '',
    level: 0
  } as any
}

export default factory({
  props: {
    serviceData: { type: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>, required: true },
    formData: Object as PropType<{ messages: EnocloudServiceDefinitions['ServiceReturnVisitMessageDto'][] }>,
    loading: Boolean as PropType<boolean>,
    showVisit: Boolean as PropType<boolean>,
    defaultTabsName: String as PropType<string>
  },

  watch: {
    serviceData: {
      handler() {
        if (this.serviceData?.id) {
          Object.assign(this.form.data, this.formData)
          this.form.data.service = this.serviceData
          if (this.serviceData.fakeService?.id) this.fake.get()
          if (this.store.accessRightsHash.SERVICE_RETURN_VISIT_HISTORY_QUERY) this.record.get()
          if (this.store.accessRightsHash.SERVICE_RETURN_VISIT_TAG_QUERY) this.complaint.get()
        }
      },
      deep: true
    }
  },

  config: {
    children: {
      operation: {
        submit: {
          async click() {
            await this.form.submit()
            this.form.data = formDataInit()
            this.form.data.serviceReturnVisit!.datetime = ''
            this.refs.form.resetFields()
            return this.record.get()
          }
        }
      },
      tabs: {
        active: 'visit'
      },
      form: {
        data: formDataInit(),
        rules: {
          'intervieweeType.code': [{ required: true, message: '请选择回访对象' }],
          'status.code': [{ required: true, message: '请选择回访状态' }]
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/service/return/visit/history',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          datetime: {
            change(value: string) {
              this.form.data.serviceReturnVisit!.datetime = value
            }
          },
          messages: {
            level: {
              change(row: EnocloudServiceDefinitions['ServiceReturnVisitMessageDto'], value: number) {
                row.level = value.toString()
              }
            }
          }
        }
      },
      tag: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/return/visit/tag',
            data: 'array'
          }
        }
      },
      fake: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.serviceData.fakeService?.id]
            }
          }
        }
      },
      record: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/return/visit/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { serviceId: this.serviceData.id }
            }
          }
        }
      },
      complaint: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/complaint',
            data: 'array',
            loading: true,
            params(params) {
              params.payload = { serviceSerialNo: this.serviceData.serialNo }
            }
          }
        }
      },
      maintenance: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId/maintenance/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = [this.serviceData.vehicle?.id]
            }
          }
        }
      },
      material: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId/material/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = [this.serviceData.vehicle?.id]
            }
          }
        }
      }
    }
  },

  mounted() {
    this.tabs.active = this.defaultTabsName ?? 'visit'
  }
})
</script>
